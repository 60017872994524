import React from 'react';

import IconNewPageWhite from '@/images/icons/icon-redirect-new-page-white.svg';
import { HeroSection, HeroSectionButton } from './styles';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <h1>Atendimento</h1>
      <p>
        Nosso setor de atendimento está preparado para atender as suas
        necessidades. Entre em contato conosco!
      </p>

      <HeroSectionButton
        target="_blank"
        href="https://cliente.generoso.com.br/coleta/coletar"
        rel="noreferrer"
      >
        Agendar coleta
        <img src={IconNewPageWhite} alt="Nova página" />
      </HeroSectionButton>
    </HeroSection>
  );
};

export default HeroSectionComponent;
