import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';
import { Link } from 'gatsby';

export const Container = styled.section`
  padding-top: 32px;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  height: 100%;
  min-height: 400px;

  width: 100%;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 10px;
  }

  @media (max-width: 666px) {
    flex-direction: column;
    min-height: 675px;
  }
`;

const ItemAnchor = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: none;

  width: 193px;
  height: 193px;

  box-sizing: border-box;

  padding: 20px 0;

  border: 1px solid ${colors.blue100};
  border-radius: 8.4px;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

  img {
    width: 83px;
    height: 83px;

    margin-bottom: 25px;
  }

  p {
    color: ${colors.blue400};

    font-size: 17px;
    font-weight: 600;
    line-height: 20px;

    margin-bottom: 0;
  }

  &:hover {
    background-color: ${colors.blue100};

    border: 1px solid ${colors.blue200};

    transition: 0.2s;
  }

  @media (max-width: 424px) {
    img {
      width: 60px;
      height: 60px;
    }

    width: 152px;
    height: 152px;

    p {
      font-size: 15px;
      line-height: 18px;
    }
  }

  @media (max-width: 320px) {
    p {
      font-size: 13px;
      line-height: 16px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const ItemLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: none;

  width: 193px;

  height: 193px;

  box-sizing: border-box;

  padding: 20px 0;

  border: 1px solid ${colors.blue100};
  border-radius: 8.4px;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

  img {
    margin-bottom: 25px;

    width: 83px;

    height: 83px;
  }

  p {
    color: ${colors.blue400};

    font-size: 17px;
    font-weight: 600;
    line-height: 20px;

    margin-bottom: 0;
  }

  &:hover {
    background-color: ${colors.blue100};

    border: 1px solid ${colors.blue200};

    transition: 0.2s;
  }

  @media (max-width: 424px) {
    img {
      width: 60px;
      height: 60px;
    }

    width: 152px;
    height: 152px;

    p {
      font-size: 15px;
      line-height: 18px;
    }
  }

  @media (max-width: 320px) {
    p {
      font-size: 13px;
      line-height: 16px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

export const WhatsAppLink = styled(ItemAnchor)``;
export const TelefoneLink = styled(ItemAnchor)`
  margin-left: 16px;

  @media (max-width: 666px) {
    margin-left: 0px;
    margin-top: 16px;
  }
`;
export const OuvidoriaLink = styled(ItemLink)`
  margin-left: 16px;

  @media (max-width: 666px) {
    margin-left: 0px;
    margin-top: 16px;
  }
`;
