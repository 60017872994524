import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Navigation = styled.div`
  width: 100%;

  display: flex;
  align-self: flex-start;

  margin: 0px;

  div {
    display: flex;

    img {
      margin: 0 20px;

      @media (max-width: 420px) {
        margin: 0 15px;
      }
    }

    p {
      font-size: 14px;
      color: ${colors.gray300};

      flex: none;

      &:not(:last-child) {
        color: ${colors.gray600};
      }

      @media (max-width: 394px) {
        font-size: 11px;
      }

      @media (max-width: 337px) {
        font-size: 10px;
      }
    }

    a {
      flex: none;

      p {
        font-size: 14px;
        color: ${colors.gray600};

        &:hover {
          color: ${colors.blue400};
        }

        @media (max-width: 394px) {
          font-size: 11px;
        }

        @media (max-width: 337px) {
          font-size: 10px;
        }
      }
    }
  }
`;
