import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import BreadCrumbs from '@/components/BreadCrumbs';

import {
  Wrapper,
  Container,
  BreadCrumbsWrapper,
  SectionWrapper,
} from '@/styles/pages/atendimento';

import HeroSection from '@/components/Pages/Atendimento/HeroSection';
import SectionOpcoesDeAtendimento from '@/components/Pages/Atendimento/SectionOpcoesDeAtendimento';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'Atendimento' },
];

const AtendimentoPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "atendimento" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Atendimento"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <Wrapper>
          <Container>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>
            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>
            <SectionWrapper>
              <SectionOpcoesDeAtendimento />
            </SectionWrapper>
          </Container>
        </Wrapper>
      </Layout>
    </>
  );
};

export default AtendimentoPage;
