import React from 'react';
import { Link } from 'gatsby';

import { Navigation } from './styles';

import ForwardArrow from '@/images/icons/icon-arrow-forward.svg';

type IBreadCrumbsData = {
  name: string;
  relativeURL?: string;
};

interface IBreadCrumbs {
  pageBreadCrumbs: Array<IBreadCrumbsData>;
  className?: any;
}

const BreadCrumbs = ({
  pageBreadCrumbs,
  className,
}: IBreadCrumbs): JSX.Element => {
  return (
    <Navigation className={className}>
      {pageBreadCrumbs.map((item, index) => (
        <div key={item.name}>
          {(item.relativeURL && (
            <Link to={item.relativeURL}>
              <p>{item.name}</p>
            </Link>
          )) || <p>{item.name}</p>}

          {index === pageBreadCrumbs.length - 1 ? null : (
            <img src={ForwardArrow} alt="Seta de navegação" />
          )}
        </div>
      ))}
    </Navigation>
  );
};

export default BreadCrumbs;
