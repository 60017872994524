import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  height: fit-content;
  min-height: 300px;
  padding-left: 29px;

  background-color: ${colors.blue100};

  p {
    max-width: 415px;
    line-height: 20px;

    margin-bottom: 22px;
  }

  h1 {
    font-weight: 400;
    font-size: 32px;

    width: 348px;
    line-height: 48px;

    margin: 0px;

    color: ${colors.gray600};
  }

  @media (max-width: 635px) {
    padding: 31px;
  }

  @media (max-width: 500px) {
    padding: 16px;

    h1 {
      margin-bottom: 15px;
    }

    p {
      max-width: 313px;

      margin-bottom: 20px;
    }

    div {
      a {
        display: flex;
      }
    }
  }

  @media (max-width: 424px) {
    h1 {
      font-size: 26px;
      line-height: 42px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 320px) {
    h1 {
      font-size: 23px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const HeroSectionButton = styled.a`
  background-color: ${colors.blue400};

  outline: none;
  border: none;
  border-radius: 8.4px;
  height: 40px;

  width: 100%;

  color: white;

  font-size: 16px;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 193px;

  img {
    margin-left: 10px;
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: white;
    background-color: #052373;
    transition: 0.2s;
  }
`;
