import React from 'react';

import { SUPPORT_NUMBER } from '@/config';

import WhatsappIcon from '@/images/paginas/atendimento/whatsapp.svg';
import TelefoneIcon from '@/images/paginas/atendimento/telefone.svg';
import OuvidoriaIcon from '@/images/paginas/atendimento/ouvidoria.svg';

import { Container, WhatsAppLink, TelefoneLink, OuvidoriaLink } from './styles';

const SectionOpcoesDeAtendimento = (): JSX.Element => {
  return (
    <Container>
      <WhatsAppLink
        href={`https://api.whatsapp.com/send?phone=${SUPPORT_NUMBER}&text=Ol%C3%A1%2C%20Generoso!%20Pode%20me%20ajudar%3F`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={WhatsappIcon} alt="Ícone do whatsapp" />
        <p>WhatsApp</p>
      </WhatsAppLink>
      <TelefoneLink href="tel:8004003567">
        <img src={TelefoneIcon} alt="Ícone de telefone" />
        <p>Telefone</p>
      </TelefoneLink>
      <OuvidoriaLink to="/atendimento/ouvidoria">
        <img src={OuvidoriaIcon} alt="Ícone de um atendente" />
        <p>Ouvidoria</p>
      </OuvidoriaLink>
    </Container>
  );
};

export default SectionOpcoesDeAtendimento;
